import { HttpParams } from '@angular/common/http';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertObjectToHttpParams(params: any): HttpParams {
  let httpParams = new HttpParams();

  // Adicione cada parâmetro ao objeto HttpParams
  for (const key in params) {
    if (
      Object.prototype.hasOwnProperty.call(params, key) &&
      params[key] !== null &&
      params[key] !== undefined &&
      params[key] !== ''
    ) {
      httpParams = httpParams.set(key, params[key]);
    }
  }

  return httpParams;
}
